import { ApolloClient, createHttpLink, ApolloLink } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import merge from 'deepmerge'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import { NextRouter } from 'next/router'

import { confirmAccount, loginPath, refreshPromptPath } from 'routes/static'
import { getAnonymousId } from 'middleware/addAnonymousId'

import ApolloCacheCreator from './ApolloCacheCreator'
import { handleGraphQlErrors } from './utils'

/**
 * Stores a singleton instance of ApolloClient
 * On the client-side we only want one instance of apollo client created
 */
let apolloClient: ApolloClient<any>

class ApolloClientBrowser {
  static getInstance(initialState = null, router: NextRouter) {
    const isServer = typeof window === 'undefined'

    // For SSR we should always use fresh instance of Apollo client.
    // For CSR we should create an instance of Apollo client and use it until the end of user session
    if (isServer) {
      return createInstance(initialState, router)
    } else {
      if (!apolloClient) {
        apolloClient = createInstance(initialState, router)
      }

      return apolloClient
    }
  }
}

const createInstance = (initialState = null, router: NextRouter): ApolloClient<any> => {
  const _apolloClient = createApolloClient(router)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(initialState, existingCache)

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  return _apolloClient
}

const createApolloClient = (router: NextRouter) => {
  return new ApolloClient({
    name: 'nl-next (Client)',
    version: process.env.NEXT_PUBLIC_REVISION,
    link: ApolloLink.from([
      createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
      getUnauthorizedLink(router),
      getRetryLink(),
      getHttpLink(),
    ]),
    cache: ApolloCacheCreator.call(),
    connectToDevTools: true,
  })
}

const getHttpLink = () => {
  let headers = {} as Record<string, string>
  const anonymousId = getAnonymousId()

  if (!!anonymousId) {
    headers['X-Anonymous-Id'] = anonymousId
  }

  return createHttpLink({
    uri: process.env.NEXT_PUBLIC_API_URI,
    credentials: 'include',
    headers,
  })
}

const getRetryLink = () => {
  return new RetryLink({
    attempts: {
      retryIf: (error, operation) => {
        return error && operation.operationName && !operation.operationName.includes('Mutation')
      },
    },
  })
}

const getUnauthorizedLink = (router: NextRouter) => {
  return onError(({ graphQLErrors }) => {
    const next = router.asPath

    handleGraphQlErrors(graphQLErrors, {
      onGraphQlValidationFailed: () => {
        window.location.href = `${refreshPromptPath}?next=${encodeURIComponent(next)}`
      },
      onLoggedOutUnauthorized: () => {
        router.push({
          pathname: loginPath,
          query: { next },
        })
      },
      onFullSessionRequired: () => {
        router.push({
          pathname: confirmAccount,
          query: { next },
        })
      },
    })
  })
}

export default ApolloClientBrowser
