import Cookies from 'js-cookie'
import { type NextRequest, type NextResponse as NextResponseType, NextResponse } from 'next/server'
import uuid from 'uuid-random'

import { isFileRequest } from './utils'

export const ANONYMOUS_COOKIE_NAME = 'anonymous_id'
const ANONYMOUS_COOKIE_MAX_AGE_IN_DAYS = 365

const addAnonymousId = (req: NextRequest, res: NextResponseType) => {
  if (isFileRequest(req)) {
    return NextResponse.next()
  }
  const existingAnonymousId = req.cookies.get(ANONYMOUS_COOKIE_NAME)

  if (!!existingAnonymousId) {
    return NextResponse.next()
  }

  const newAnonymousId = uuid()

  res.cookies.set(ANONYMOUS_COOKIE_NAME, newAnonymousId, {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    maxAge: ANONYMOUS_COOKIE_MAX_AGE_IN_DAYS * 24 * 60 * 60 * 1000,
    secure: true,
  })
}

export const getAnonymousId = () => Cookies.get(ANONYMOUS_COOKIE_NAME)

export default addAnonymousId
