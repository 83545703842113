import { GetServerSidePropsContext } from 'next'

import {
  CountryCode,
  Locale,
  VIEWER_LOCALE_COOKIE_NAME,
  LOCALE_MAPPING,
  SUPPORTED_LOCALES,
  CountryISOCode,
} from 'locale/constants'

export const getLocaleFromCountry = (countryCode: CountryCode | null): Locale | undefined => {
  if (!countryCode) return undefined
  for (const key in LOCALE_MAPPING) {
    if (LOCALE_MAPPING[key as Locale].db === countryCode) {
      return LOCALE_MAPPING[key as Locale].locale
    }
  }
  return LOCALE_MAPPING.default.locale
}

export const getLocaleFromServerRequest = (
  context: GetServerSidePropsContext
): Locale | undefined => {
  return context.req.cookies[VIEWER_LOCALE_COOKIE_NAME] as Locale
}

export const getLocaleCodeFromLocale = (locale: Locale) => {
  if (!LOCALE_MAPPING[locale] || !LOCALE_MAPPING[locale].localeCode) return undefined
  return LOCALE_MAPPING[locale].localeCode
}

export const getCountryFromLocale = (locale: Locale) => {
  if (!SUPPORTED_LOCALES.includes(locale)) return LOCALE_MAPPING.default.db
  return LOCALE_MAPPING[locale].db
}

export const isLocaleSupported = (locale: Locale) => {
  return SUPPORTED_LOCALES.includes(locale)
}

export const buildLocalizedCanonicalLink = (locale: Locale, path: string) => {
  return `/${locale}${path}`
}

export const getISOCountryCodeFromLocale = (locale: Locale) => {
  if (!SUPPORTED_LOCALES.includes(locale)) return LOCALE_MAPPING.default.isoCode
  return LOCALE_MAPPING[locale].isoCode
}

export const getCountryFromISOCode = (isoCountryCode: CountryISOCode) => {
  for (const key in LOCALE_MAPPING) {
    if (LOCALE_MAPPING[key as keyof typeof LOCALE_MAPPING].isoCode === isoCountryCode) {
      return LOCALE_MAPPING[key as keyof typeof LOCALE_MAPPING].db
    }
  }
  return LOCALE_MAPPING.default.db
}

const isLocalized = (path: string) => {
  const regex = SUPPORTED_LOCALES.map((c) => `/${c}`).join('|')
  const isLocalized = path.match(regex)

  return !!isLocalized
}

export const getLocalizedPath = (path: string, locale: string) => {
  if ((typeof path === 'string' && !path.startsWith('/')) || isLocalized(path)) {
    return path
  }

  return `/${locale}${path}`
}
