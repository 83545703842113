import { absoluteURL, cleanWhitespace } from 'app/utils'

type PageData = {
  title?: string
  description?: string
  shareImage?: string
}

type PagePresenterOptions = {
  MAX_DESCRIPTION_LENGTH: number
  DEFAULT_SHARE_IMAGE: string
}

class PagePresenter {
  DEFAULT_OPTIONS = {
    MAX_DESCRIPTION_LENGTH: 500,
    DEFAULT_SHARE_IMAGE: '/images/nl-default-share-image.jpg',
  }

  data: PageData

  options: PagePresenterOptions

  constructor(data: PageData, options = {}) {
    this.data = data
    this.options = { ...this.DEFAULT_OPTIONS, ...options }
  }

  get title() {
    const { title } = this.data
    return title ? `${cleanWhitespace(title)} | Nanny Lane` : 'Nanny Lane'
  }

  get description() {
    const trimmedDescription = cleanWhitespace(this.data.description || '')
    const { MAX_DESCRIPTION_LENGTH } = this.options
    return trimmedDescription.length > MAX_DESCRIPTION_LENGTH
      ? `${trimmedDescription.slice(0, MAX_DESCRIPTION_LENGTH - 3)}…`
      : trimmedDescription
  }

  get shareImage() {
    const { shareImage } = this.data
    return absoluteURL(shareImage || this.options.DEFAULT_SHARE_IMAGE)
  }
}

export default PagePresenter
