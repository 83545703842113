// This file is generated by yarn codegen, PLEASE DO NOT EDIT!
/* eslint-disable */

import * as Types from '../../@graph/types/global.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FeatureFlagsQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FeatureFlagsQueryQuery = { __typename?: 'Query', featureFlags: Array<{ __typename?: 'FeatureFlag', name: string, enabled: boolean }> };


export const FeatureFlagsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FeatureFlagsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeatureFlags"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeatureFlags"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"featureFlags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]} as unknown as DocumentNode<FeatureFlagsQueryQuery, FeatureFlagsQueryQueryVariables>;