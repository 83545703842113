import { uniqueId } from 'lodash'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TransitionGroup } from 'react-transition-group'
import css from '@styled-system/css'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import FadeTransition from '@ui/common/transitions/FadeTransition'
import { zIndex } from '@ui/theme/zindex'
import { ContentBlock } from '@ui/layout/ContentBlock'
import { respondTo } from '@ui/theme'
import { Stack } from '@ui/layout/stack/Stack'

type SnackbarProviderProps = {
  children: ReactNode
}

type Alert = {
  id: string
  text: string
}

const AUTO_DISMISS_IN_MS = 4000

export const SnackbarContext = createContext({ addAlert: (text: string) => {} })

export const useSnackbar = () => useContext(SnackbarContext)

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const router = useRouter()
  const [alerts, setAlerts] = useState<Alert[]>([])

  const activeAlertIds = alerts.map(({ id }) => id).join(',')
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS_IN_MS
      )
      return () => clearTimeout(timer)
    }
  }, [activeAlertIds])

  const addAlert = useCallback((text: string) => {
    setAlerts((alerts) => {
      const alertAlreadyExists = alerts.some(({ text: oldText }) => oldText === text)
      if (alertAlreadyExists) return alerts

      return [{ id: uniqueId(), text }, ...alerts]
    })
  }, [])

  // Toggle any alerts indicated via URL query parameters. Used in emails
  useEffect(() => {
    const { alert } = router.query
    if (alert && typeof alert === 'string') addAlert(alert)
  }, [addAlert, router.query])

  const value = useMemo(
    () => ({
      addAlert,
    }),
    [addAlert]
  )

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snacks>
        <TransitionGroup>
          {alerts.map(({ text, id }) => (
            <FadeTransition key={id}>
              <Snackbar data-test-id='snack-bar'>{text}</Snackbar>
            </FadeTransition>
          ))}
        </TransitionGroup>
      </Snacks>
    </SnackbarContext.Provider>
  )
}

const Snacks = styled(ContentBlock)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.snackbar};
  pointer-events: none;

  ${css({
    marginBottom: 6,
  })}

  ${respondTo.desktop`
    margin: 0;
  `}
`

const Snackbar = styled(Stack)`
  min-width: calc(100vw - 32px);
  width: 100%;
  border-radius: 4px;
  padding: 12px;

  ${css({
    marginBottom: 3,
    color: 'white',
    backgroundColor: 'grey.3',
  })}

  ${respondTo.desktop`
    width: 100%;
    max-width: 464px;
    min-width: 288px;
  `}
`
