import { flexbox } from 'styled-system'

import { Theme } from '@ui/theme'

export const alignChildren = (breakpoint: keyof Theme['breakpoints']) => {
  return ({ theme, align = '', valign = '', direction = 'vertical' }: any) => {
    direction = direction[breakpoint] || direction || ''

    align = translateAlignment(align[breakpoint] || align, direction === 'horizontal-reverse')
    valign = translateValignment(valign[breakpoint] || valign, direction === 'vertical-reverse')

    if (!align && !valign) return ''

    let values = {}

    if (direction === 'horizontal' || direction === 'horizontal-reverse') {
      values = { theme, justifyContent: align, alignItems: valign }
    }

    if (direction === 'vertical' || direction === 'vertical-reverse') {
      values = { theme, justifyContent: valign, alignItems: align }
    }

    return flexbox(values)
  }
}

const translateAlignment = (align: string, reversed: boolean) => {
  if (align === 'left') {
    if (reversed) return 'flex-end'
    return 'flex-start'
  }
  if (align === 'right') {
    if (reversed) return 'flex-start'
    return 'flex-end'
  }
  return align
}

const translateValignment = (align: string, reversed: boolean) => {
  if (align === 'top') {
    if (reversed) return 'flex-end'
    return 'flex-start'
  }
  if (align === 'bottom') {
    if (reversed) return 'flex-start'
    return 'flex-end'
  }
  return align
}
