import Image from 'next/image'
import heroImage from 'assets/images/rocket-fail.svg'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import { Heading } from '@ui/typography/Heading'
import { Body } from '@ui/typography/Body'
import Button from '@ui/button/Button'
import { ContentBlock } from '@ui/layout/ContentBlock'
import Stack from '@ui/layout/stack'
import { Box } from '@ui/layout/Box'
import PageHead from 'page/PageHead'

type UnknownErrorProps = {
  eventId?: string | null
}

const UnknownError = ({ eventId }: UnknownErrorProps) => {
  const router = useRouter()

  return (
    <>
      <PageHead title='Error | Nanny Lane' noindex />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Stack gap='2'>
          <ContentBlock maxWidth={{ _: '100%', tablet: '100%', desktop: '75%' }}>
            <HeroImage src={heroImage} alt='Page Not Found' />
          </ContentBlock>
          <ContentBlock
            maxWidth={{
              _: 'page.error.mobile',
              tablet: 'page.error.tablet',
              desktop: 'page.error.desktop',
            }}
            px={{ _: '4', desktop: '5' }}
          >
            <Stack gap='4' align='center'>
              <Heading as='h1' size='xl'>
                Oops
              </Heading>
              <Body textAlign='center'>
                Something went wrong. We&apos;ve been notified and will get right on it!
              </Body>
              <Button
                width={1}
                onClick={() =>
                  window.open(
                    `https://nannylanehelp.zendesk.com/hc/en-us/requests/new?tf_subject=Nannylane Error code: ${
                      eventId ?? 'unknown'
                    }`,
                    '_blank'
                  )
                }
              >
                Report feedback
              </Button>
              <Button width={1} onClick={() => router.reload()}>
                Try again
              </Button>
            </Stack>
          </ContentBlock>
        </Stack>
      </Box>
    </>
  )
}

const HeroImage = styled(Image)`
  width: 100%;
  height: auto;
`

export default UnknownError
