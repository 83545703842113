export const sizes = {
  page: {
    error: {
      mobile: '100%',
      tablet: '608px',
      desktop: '928px',
    },
    content: {
      maxWidth: '1152px',
    },
    card: {
      small: '464px',
    },
    dashboard: {
      mobile: '100%',
      tablet: '624px',
      desktop: '944px',
    },
    listing: {
      mobile: '100%',
      tablet: '624px',
    },
    minimal: {
      mobile: '100%',
      tablet: '464px',
    },
  },
  ui: {
    listing: {
      cardHeight: '250px',
      cardWidth: '304px',
    },
  },
}
