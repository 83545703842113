import { ApolloClient, ApolloLink } from '@apollo/client'
import { useEffect, useState } from 'react'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { useRouter } from 'next/router'

import ApolloClientBrowser from './ApolloClientBrowser'
import { hasSubscriptionOperation } from './utils'

/**
 * Returns a browser ApolloClient instance with the cache hydrated with the
 * cached data from Apollo Client's server side instance. Pulls the data that
 * was added to pageProps using addApolloState in the page's GetServerSideProps
 *
 * @param pageProps
 * @returns apollo client for browser
 */
const useApolloClient = (initialApolloState: any): ApolloClient<any> => {
  const router = useRouter()
  const [actionCable, setActionCable] = useState<any>(null)
  const state = initialApolloState

  /**
   * Next JS will try to load actioncable during server side rendering if you simply import it like any other library
   * This will cause an error, as there is code in actioncable that references window object without checking if it is defined or not
   *
   * To make sure it doesn't happen, we need to load actioncable client-side
   * Loading actioncable in async function and running the function inside useEffect ensures that the library is only loaded client-side
   */
  const loadActionCable = async () => {
    const actionCableLib = await import('actioncable')
    setActionCable(actionCableLib as any)
  }

  useEffect(() => {
    loadActionCable()
  }, [])

  const client = ApolloClientBrowser.getInstance(state, router)
  const hasAddedActionCableLink = client.link.request.length > 1

  if (actionCable && !hasAddedActionCableLink) {
    const cable = actionCable.createConsumer(process.env.NEXT_PUBLIC_NL_ACTIONCABLE_SERVER_URL)
    const actionCableLink = new ActionCableLink({ cable })
    const splitLink = ApolloLink.split(hasSubscriptionOperation, actionCableLink, client.link)
    client.setLink(splitLink)
  }

  return client
}

export default useApolloClient
