export const space = [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
  16, // 4
  32, // 5
  64, // 6
  80, // 7
  96, // 8
] as const

export const topNavigationZ = 500
