import styled from 'styled-components'
import { flexbox } from 'styled-system'
import css from '@styled-system/css'

import { respondTo, Responsive } from '@ui/theme'

import { alignChildren } from './align-children'
import { marginOnChildren } from './margin-on-children'

const propsToOmit = ['gap', 'direction', 'align', 'valign']

const config = {
  shouldForwardProp: (prop: string) => !propsToOmit.includes(prop),
}

export type StackProps = {
  gap?: Responsive<string | number>
  direction?: Responsive<'vertical' | 'horizontal' | 'vertical-reverse' | 'horizontal-reverse'>
  align?: Responsive<'left' | 'center' | 'right' | 'space-between' | 'space-around'>
  valign?: Responsive<'top' | 'center' | 'bottom'>
}

export const Stack = styled.div.withConfig<StackProps>(config)`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow: none;

  ${direction}
  // &&& per https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&& {
    ${(props) => {
      const styles = marginOnChildren(props as any)

      const result = css({ ...styles, theme: props.theme })

      return result
    }}
  }

  ${alignChildren('_')}

  ${respondTo.tablet`
    ${alignChildren('tablet')}
  `}

  ${respondTo.desktop`
    ${alignChildren('desktop')}
  `}
`

Stack.displayName = 'Stack'

Stack.defaultProps = {
  direction: 'vertical',
}

/**
 * Translates Stack DSL to Flexbox DSL
 *
 * - `vertical` => `column`
 * - `horizontal` => `row`
 *
 * Also works with responsive objects
 */
function direction({ direction, theme }: any) {
  const translateDirection = (direction: any): any => {
    if (direction === 'vertical') return 'column'
    if (direction === 'horizontal') return 'row'
    if (direction === 'vertical-reverse') return 'column-reverse'
    if (direction === 'horizontal-reverse') return 'row-reverse'
    return direction
  }
  let flexDirection: Responsive<string> = translateDirection(direction)

  if (typeof direction === 'object') {
    flexDirection = {
      _: translateDirection(direction._),
      tablet: translateDirection(direction.tablet),
      desktop: translateDirection(direction.desktop),
    }
  }

  return flexbox({ flexDirection, theme })
}
