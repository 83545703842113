import {
  ApolloCache,
  DocumentNode,
  FetchResult,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client'
import { ApolloError, GraphQLErrors } from '@apollo/client/errors'
import { ErrorOption } from 'react-hook-form'

import { FieldError, MutationWithErrorsResult } from './types/global.generated'

export const hasSubscriptionOperation = ({ query: { definitions } }: Operation) => {
  return definitions.some((definitionNode) => {
    const isSub =
      definitionNode.kind === 'OperationDefinition' && definitionNode.operation === 'subscription'
    return isSub
  })
}

export const extractFirstErrorFromMutation = <MutationResponse>(
  response: FetchResult<MutationResponse>
): FieldError | string | undefined => {
  // An allFields error will take presedence over field errors
  const allFieldsError = extractAllFieldsErrorFromMutation(response)

  if (allFieldsError) {
    return allFieldsError
  }

  const fieldErrors = extractFieldErrorsFromMutation(response)

  if (fieldErrors && fieldErrors.length > 0) {
    return fieldErrors[0]
  }

  return undefined
}

export const extractFieldErrorsFromMutation = <MutationResponse>(
  response: FetchResult<MutationResponse>
): FieldError[] | undefined => {
  if (response.data) {
    const result = Object.values(response.data)[0] as MutationWithErrorsResult
    if (result.errors?.fields && result.errors.fields.length > 0) {
      return result.errors.fields.filter((field) => {
        return field !== null
      }) as FieldError[]
    } else {
      return undefined
    }
  }
}

export const getErrorString = (errorMessage: FieldError | string) => {
  let errorString = ''
  const fieldError = errorMessage as FieldError

  if (typeof errorMessage === 'string') {
    errorString = errorMessage
  } else if (!!fieldError?.error && !!fieldError?.name) {
    errorString = fieldError.name + ': ' + fieldError.error
  }
  return errorString
}

export const extractAllFieldsErrorFromMutation = <MutationResponse>(
  response: FetchResult<MutationResponse>
): string | undefined => {
  if (response.data) {
    const result = Object.values(response.data)[0] as MutationWithErrorsResult
    if (result.errors?.allFields) {
      return result.errors.allFields
    }
  }
}

export const updateViewerCache = <T>(
  cache: ApolloCache<NormalizedCacheObject>,
  newUser: T | null | undefined,
  fragmentDoc: DocumentNode,
  fragmentName: string
) => {
  if (newUser) {
    cache.writeFragment({
      id: 'Viewer:{}',
      fragment: fragmentDoc,
      fragmentName,
      data: {
        user: newUser,
      },
    })
  }
}

export const clearViewerCache = <T>(
  cache: ApolloCache<NormalizedCacheObject>,
  fragmentDoc: DocumentNode,
  fragmentName: string
) => {
  cache.writeFragment({
    id: 'Viewer:{}',
    fragment: fragmentDoc,
    fragmentName,
    data: {
      user: null,
    },
  })
}

export const handleGraphQlErrors = (
  graphQLErrors: GraphQLErrors | undefined,
  callbacks: {
    onGraphQlValidationFailed: () => void
    onLoggedOutUnauthorized: () => void
    onFullSessionRequired: () => void
  }
) => {
  if (graphQLErrors) {
    for (const graphError of graphQLErrors) {
      if (graphError.extensions?.code === 'GRAPHQL_VALIDATION_FAILED') {
        callbacks.onGraphQlValidationFailed()
        break
      }

      if (graphError.extensions?.code === 'LOGGED_OUT_UNAUTHORIZED') {
        callbacks.onLoggedOutUnauthorized()
        break
      }

      /**
       * If a user is trying to access something in the graph that requires
       * a full session but is auto-logged in this error code will be returned.
       * This will redirect the user to the confirm account page.
       */
      if (graphError.extensions?.code === 'FULL_SESSION_REQUIRED') {
        callbacks.onFullSessionRequired()
        break
      }
    }
  }
}

/**
 * Returns a Tuple of the error name and error options to be passed to react hook form setError
 * @param error - This would be a GraphQL Exception or unknown error
 * @returns
 */
export const getSetErrorArgsFromGraphQlError = <T>(
  error: ApolloError | unknown
): [T, ErrorOption] => {
  const errorName = 'root.serverError' as T
  const message = 'Something went wrong, please try again.'

  if (error instanceof ApolloError) {
    const errorCode = (error.graphQLErrors?.[0]?.extensions?.code ?? 'unknown') as string
    return [errorName, { type: errorCode, message: error.graphQLErrors?.[0]?.message ?? message }]
  } else {
    return [errorName, { type: 'unknown', message }]
  }
}

/**
 * Returns a Tuple of the error name and error options to be passed to react hook form setError
 * @param error - This would be a mutation error with allFields or field errors
 * @returns
 */
export const getSetErrorArgsFromMutationError = <T>(
  error: FieldError | string,
  fieldErrorToFormFieldMap?: Record<string, string>
): [T, ErrorOption] => {
  const type = 'custom'

  if (typeof error === 'string') {
    return ['root.serverError' as T, { type, message: error }]
  } else {
    const formFieldName = fieldErrorToFormFieldMap && fieldErrorToFormFieldMap[error.name]

    // If the error name matches a field in the form, return that field name
    // Otherwise, return as a server root error
    return formFieldName
      ? [formFieldName as T, { type, message: error.error }]
      : ['root.serverError' as T, { type, message: error.error }]
  }
}
