import { ListingType, ProfileType } from '@graph/types/global.generated'
import { ViewerGlobalFragment } from '@graph/fragments/ViewerGlobal.generated'

import { mediumGreen, mediumPurple, mediumYellow, powderPink, mediumPink } from './colors'

const nannyGradient = `linear-gradient(315deg, ${mediumGreen}, ${mediumPurple})`
const familyGradient = `linear-gradient(315deg, ${mediumYellow}, ${powderPink})`
const shareGradient = `linear-gradient(315deg, ${mediumPurple}, ${mediumPink})`

export type GradientType = keyof typeof gradient

export const gradient = {
  nanny: nannyGradient,
  family: familyGradient,
  share: shareGradient,
  familypair: shareGradient,
  neutral: familyGradient,
}

export const gradientForViewer = (viewer: ViewerGlobalFragment) => {
  return toGradientType(viewer.user?.profile?.type)
}

export const toGradientType = (type?: GradientType | ListingType | ProfileType): GradientType => {
  if (!type) return 'neutral'

  return type.toLowerCase().replace('listing', '').replace('n4h', '') as GradientType
}

export const gradientForType = (type: GradientType | ListingType) => {
  if (!type) return gradient.neutral

  const typeParam = toGradientType(type)

  return gradient[typeParam]
}
