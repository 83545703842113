import { Stack as StackComponent } from './Stack'
import { StackItem } from './StackItem'

type StackTypes = typeof StackComponent & {
  Item: typeof StackItem
}

const Stack = StackComponent as StackTypes
Stack.Item = StackItem
Stack.Item.displayName = 'Stack.Item'

export default Stack
