export type MarginOnChildrenProps = {
  gap: any
  direction: any
}

/**
 * This function generates a style sheet for a Stack based on it's
 * `gap` and `direction` values. Since both of these values can be
 * responsive, it is quite a complex problem.
 *
 *
 * The process involves
 * 1. Setting the margin on all children for each breakpoint.
 * 2. Unsetting the margin on the first visual element
 * 3. Making sure these don't conflict when reversing order
 */
export const marginOnChildren = ({ gap = '0', direction = 'vertical' }: MarginOnChildrenProps) => {
  const allKey = '> *'
  const firstKey = '> *:first-child'
  const lastKey = '> *:last-child'

  if (typeof direction === 'string') {
    let gaps = gap
    if (typeof gaps === 'object') {
      const mobile = gaps[0] || gaps._
      const tablet = gaps[1] || gaps.tablet
      const desktop = gaps[2] || gaps.desktop
      gaps = []
      if (mobile) gaps[0] = mobile
      if (tablet) gaps[1] = tablet
      if (desktop) gaps[2] = desktop
    }
    const side = getMarginSide(direction)
    const oppositeSide = getOppositeMarginSide(side)
    const styles: Record<string, any> = {
      [allKey]: { [side]: gaps, [oppositeSide]: '0' },
    }

    if (direction.includes('reverse')) {
      styles[lastKey] = { [side]: '0' }
    } else {
      styles[firstKey] = { [side]: '0' }
    }

    return styles
  }
  const all: any = {}
  const gapIsResponsive = typeof gap === 'object'

  /**
   * Breakpoint Props
   */
  const mobile = {
    index: 0,
    name: '_',
    direction: direction._ || direction[0],
    gap: gapIsResponsive ? gap._ || gap[0] : gap,
  }

  const tablet = {
    index: 1,
    name: 'tablet',
    direction: direction.tablet || direction[1],
    gap: gap.tablet || gap[1],
  }

  const desktop = {
    index: 2,
    name: 'desktop',
    direction: direction.desktop || direction[2],
    gap: gap.desktop || gap[2],
  }

  /**
   * Styles
   */
  const styles: any = {
    '> *': all,
  }

  /**
   * Set Margin for Breakpoints
   */

  setGapForBreakpoint(all, [mobile])

  if (tablet.direction || tablet.gap) {
    setGapForBreakpoint(all, [tablet, mobile])
  }

  if (desktop.direction || desktop.gap) {
    setGapForBreakpoint(all, [desktop, tablet, mobile])
  }

  /** First Child */
  const directions = [mobile.direction, tablet.direction, desktop.direction].filter((p) => !!p)
  const numReversed = directions.filter((direction) => direction?.includes('reverse')).length

  if (numReversed === 0) {
    styles[firstKey] = { marginTop: '0', marginLeft: '0' }
  } else if (numReversed === directions.length) {
    styles[lastKey] = { marginTop: '0', marginLeft: '0' }
  } else {
    // TODO: More complex
    const first: any = {}
    const last: any = {}

    // Reset Margin
    resetGapsForBreakpoint(all, first, last, [mobile])
    if (tablet.direction) resetGapsForBreakpoint(all, first, last, [tablet, mobile])
    if (desktop.direction) resetGapsForBreakpoint(all, first, last, [desktop, tablet, mobile])

    // Set Values
    styles[firstKey] = first
    styles[lastKey] = last
  }

  return styles
}

const resetGapsForBreakpoint = (all: any, first: any, last: any, breakpoints: any[]) => {
  const breakpoint = breakpoints[0]
  const smallerBreakpoints = breakpoints.slice(1)
  const marginSide = getMarginSide(breakpoint.direction)
  const firstElStyles = breakpoint.direction.includes('reverse') ? last : first
  const lastElStyles = breakpoint.direction.includes('reverse') ? first : last

  firstElStyles[marginSide] = firstElStyles[marginSide] || []

  const breakpointWithMarginOnFirstEl = smallerBreakpoints.find(
    (breakpoint) => firstElStyles[marginSide][breakpoint.index]
  )
  const prevMarginOnFirstEl = firstElStyles[marginSide][breakpointWithMarginOnFirstEl?.index || -1]
  if (typeof prevMarginOnFirstEl === 'undefined' || ~~prevMarginOnFirstEl > 0) {
    firstElStyles[marginSide][breakpoint.index] = '0'
  }

  const allMarginForLastEl = lastElStyles[marginSide] || []
  let prevMarginOnLastEl: any
  smallerBreakpoints.forEach((bp) => {
    if (!prevMarginOnLastEl) {
      prevMarginOnLastEl = allMarginForLastEl[bp.index]
    }
  })

  if (typeof prevMarginOnLastEl !== 'undefined' && ~~prevMarginOnLastEl === 0) {
    lastElStyles[marginSide] = lastElStyles[marginSide] || []

    let marginOnCurrentBreakpoint: any
    smallerBreakpoints.forEach((bp) => {
      if (!marginOnCurrentBreakpoint) {
        marginOnCurrentBreakpoint = all[marginSide][bp.index]
      }
    })
    lastElStyles[marginSide][breakpoint.index] = marginOnCurrentBreakpoint
  }
}

const setGapForBreakpoint = (styles: any, breakpoints: any[]) => {
  const smallerBreakpoints = breakpoints.slice(1)
  const index = breakpoints[0].index
  const gap = breakpoints.find((breakpoint) => breakpoint.gap)?.gap
  const direction = breakpoints.find((breakpoint) => breakpoint.direction).direction

  // Set Margin
  const marginSide = getMarginSide(direction)
  const oppositeMargin = getOppositeMarginSide(marginSide)
  styles[marginSide] = styles[marginSide] || []
  styles[oppositeMargin] = '0'

  const lastBreakpointWithMargin = smallerBreakpoints.find((breakpoint) => {
    const prop = styles[marginSide]
    return prop && prop[breakpoint.index]
  })
  if (!lastBreakpointWithMargin || styles[marginSide][lastBreakpointWithMargin.index] !== gap) {
    styles[marginSide][index] = gap
  }

  // Unset Margin on Other Side
  const otherSide = direction.includes('vertical') ? 'marginLeft' : 'marginTop'
  const lastBreakpointWithMarginOnOtherSide = smallerBreakpoints.find((breakpoint) => {
    const prop = styles[otherSide]
    return prop && prop[breakpoint.index]
  })
  if (
    lastBreakpointWithMarginOnOtherSide &&
    ~~styles[otherSide][lastBreakpointWithMarginOnOtherSide.index] !== 0
  ) {
    styles[otherSide] = styles[otherSide] || []
    styles[otherSide][index] = '0'
  }
}

const getMarginSide = (direction: string) =>
  direction.includes('vertical') ? 'marginTop' : 'marginLeft'

const getOppositeMarginSide = (margin: 'marginTop' | 'marginLeft') =>
  margin === 'marginTop' ? 'marginBottom' : 'marginRight'
