import { ReactNode } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import favIcon16x16 from 'public/favicon/favicon-16x16.png'
import favIcon32x32 from 'public/favicon/favicon-32x32.png'
import favIconIco from 'public/favicon/favicon.ico'
import appleTouchIcon from 'public/favicon/apple-touch-icon.png'
import safariPinnedTab from 'public/favicon/safari-pinned-tab.svg'

import PagePresenter from '@presenters/PagePresenter'
import { absoluteURL } from 'app/utils'
import { darkGrey } from '@ui/theme/colors'
import { getLocaleCodeFromLocale } from 'locale/utils'
import { Locale, LOCALE_MAPPING } from 'locale/constants'

type PageHeadProps = {
  title?: string
  description?: string
  noindex?: boolean
  countrySlug?: string
  stateSlug?: string
  city?: string
  canonical?: string
  shareImage?: {
    src: string
    width?: number
    height?: number
  }
  breadcrumbList?: {
    '@type': string
    position: number
    item: {
      name: string
      '@id': string
    }
  }[]
  jobPosting?: { '@context': string; '@type': string } & { [key: string]: any }
  children?: ReactNode
}

const PageHead = ({
  title,
  description,
  noindex,
  countrySlug,
  stateSlug,
  city,
  canonical,
  shareImage,
  breadcrumbList,
  jobPosting,
  children,
}: PageHeadProps) => {
  const router = useRouter()
  const page = new PagePresenter({ title, description, shareImage: shareImage?.src })
  const canonicalUrl = absoluteURL(canonical || router.asPath)
  const localeCode =
    getLocaleCodeFromLocale(router.locale as Locale) || LOCALE_MAPPING.default.localeCode
  const pinterestId = process.env.NEXT_PUBLIC_PINTEREST_VERIFICATION_ID
  const hasPinterestId = pinterestId !== 'false'

  return (
    <Head>
      <title>{page.title}</title>

      <meta name='theme-color' content={darkGrey} />
      <meta name='msapplication-config' content='/static/browserconfig.xml' />
      {description && <meta name='description' content={page.description} />}
      {stateSlug && countrySlug && (
        <meta name='geo.region' content={`${countrySlug}-${stateSlug}`} />
      )}
      {city && <meta name='geo.placename' content={city} />}
      <meta charSet='utf-8' />
      <meta
        name='viewport'
        content={[
          'width=device-width',
          'height=device-height',
          'initial-scale=1',
          'maximum-scale=1',
          'minimum-scale=1',
          'user-scalable=0',
        ].join(',')}
      />

      {/* Facebook OG tags */}
      <meta property='fb:app_id' content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID} />
      <meta property='og:url' content={canonicalUrl} />
      <meta property='og:type' content='website' />
      <meta property='og:locale' content={localeCode} />
      <meta property='og:site_name' content='Nanny Lane' />
      <meta property='og:title' content={page.title} />
      <meta property='og:image' content={page.shareImage} />
      {shareImage?.width && <meta property='og:image:width' content={String(shareImage.width)} />}
      {shareImage?.height && (
        <meta property='og:image:height' content={String(shareImage.height)} />
      )}
      {description && <meta property='og:description' content={page.description} />}

      {/* Twitter card tags */}
      <meta property='twitter:site' content='shareNannyLane' />
      <meta property='twitter:card' content='summary' />
      <meta property='twitter:url' content={canonicalUrl} />
      <meta property='twitter:title' content={page.title} />
      <meta property='twitter:image' content={page.shareImage} />
      {description && <meta property='twitter:description' content={page.description} />}

      {hasPinterestId && <meta name='p:domain_verify' content={pinterestId} />}

      {jobPosting && (
        <script
          key='jobPosting'
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jobPosting) }}
        />
      )}

      {breadcrumbList && (
        <script
          key='breadcrumbList'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbList,
            }),
          }}
        />
      )}

      {noindex ? (
        <meta key='robots' name='robots' content='noindex' />
      ) : (
        <>
          <meta key='robots' name='robots' content='index,follow' />
          <meta key='googlebot' name='googlebot' content='index,follow' />
        </>
      )}
      <link rel='icon' type='image/png' sizes='32x32' href={favIcon32x32.src} />
      <link rel='icon' type='image/png' sizes='16x16' href={favIcon16x16.src} />
      <link rel='shortcut icon' href={favIconIco.src} />
      <link rel='mask-icon' href={safariPinnedTab.src} color={darkGrey} />
      <link rel='apple-touch-icon' sizes='180x180' href={appleTouchIcon.src} />
      <link rel='manifest' href='/static/android.manifest' />
      <link rel='canonical' href={canonicalUrl} />
      {children}
    </Head>
  )
}

export default PageHead
