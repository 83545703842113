import styled from 'styled-components'

import BaseTransition from '@ui/common/transitions/BaseTransition'

const FadeTransition = styled(BaseTransition)`
  transition: opacity ${(props) => props.speed || 200}ms linear;

  &.transition-enter {
    opacity: 0;
  }

  &.transition-enter-active {
    opacity: 1;
  }

  &.transition-exit {
    opacity: 1;
  }

  &.transition-exit-active {
    opacity: 0;
  }
`

export default FadeTransition
