import { ReactNode, type ReactElement } from 'react'
import type { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'

import theme from '@ui/theme'
import GlobalStyle from '@ui/theme/GlobalStyle'
import UnkownRenderError from '@ui/errors/UnkownError'
import { getOrganizationStructuredData } from 'app/utils'
import { ViewerGlobalProvider } from 'viewer/ViewerGlobalContext'
import ExperimentsProvider from 'app/ExperimentsProvider'
import { LocaleContextProvider } from 'locale/LocaleContext'
import { FeatureFlagsProvider } from 'app/FeatureFlagContext'
import useAppInitialize from 'app/useAppInitialize'
import { Locale } from 'locale/constants'
import { PageCommonFragment } from '@graph/fragments/PageCommon.generated'
import { SnackbarProvider } from '@ui/snackbar/SnackbarContext'

const CountrySelectPanel = dynamic(() => import('app/CountrySelectPanel'), {
  ssr: false,
})

/**
 * GlobalPageProps are the props that are are added to pageProps in getServerSideProps
 * by addGlobalPageProps decorator.
 */
export type GlobalPageProps = {
  localeFromServerCookie: Locale | null
  inclueCountrySelectPanel?: boolean
  __APOLLO_STATE__: any
}

/**
 * ServerSideProps are the props that are passed to the page component from the server.
 * Each page's getServerSideProps call decorates the props with additional items that
 * are not needed by the page component which is why we omit them.
 */
type ServerSideProps<P> = Omit<P, keyof GlobalPageProps>

export type NLPage<Props = {}, LayoutProps = {}> = NextPage<Props> & {
  getLayout?: (page: ReactElement, pageProps: Props & LayoutProps) => ReactNode
}

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NLPage<ServerSideProps<P>, {}>
}

const App = <P extends {}>({
  Component,
  pageProps,
}: AppPropsWithLayout<P & GlobalPageProps & PageCommonFragment>) => {
  const organizationStructuredData = getOrganizationStructuredData()
  const { __APOLLO_STATE__, localeFromServerCookie, inclueCountrySelectPanel, ...serverSideProps } =
    pageProps
  const viewer = serverSideProps?.viewer
  const { apolloClient } = useAppInitialize(__APOLLO_STATE__, viewer)

  // TODO: Make getLayout required for all pages. Once implemented remove this
  // and call Component.getLayout directly.
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Head>
          <title>Nanny Lane</title>
          <meta
            name='viewport'
            content={[
              'width=device-width',
              'height=device-height',
              'initial-scale=1',
              'maximum-scale=1',
              'minimum-scale=1',
              'user-scalable=0',
            ].join(',')}
          />
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: organizationStructuredData }}
          />
        </Head>
        <Sentry.ErrorBoundary fallback={<UnkownRenderError />}>
          <FeatureFlagsProvider flags={serverSideProps.featureFlags}>
            <ViewerGlobalProvider viewerFromServer={viewer}>
              <ExperimentsProvider>
                <LocaleContextProvider localeFromServerCookie={localeFromServerCookie}>
                  <SnackbarProvider>
                    {getLayout(<Component {...serverSideProps} />, pageProps)}
                    {inclueCountrySelectPanel && <CountrySelectPanel />}
                  </SnackbarProvider>
                </LocaleContextProvider>
              </ExperimentsProvider>
            </ViewerGlobalProvider>
          </FeatureFlagsProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
