import { css } from 'styled-components'

export type Responsive<T> = T | T[] | { _?: T; tablet?: T; desktop?: T }

export const breakpointSizes = {
  tablet: 672,
  desktop: 992,
}

export const breakpoints: any = [`${breakpointSizes.tablet}px`, `${breakpointSizes.desktop}px`]

breakpoints.tablet = breakpoints[0]
breakpoints.desktop = breakpoints[1]

export type RespondTo = {
  tablet: any
  desktop: any
}

export const respondTo: RespondTo = Object.keys(breakpointSizes).reduce<any>((acc, label) => {
  acc[label] = (...args: any[]) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${
        // @ts-ignore
        css(...args)
      };
    }
  `
  return acc
}, {})

export const restrainTo: RespondTo = Object.keys(breakpointSizes).reduce<any>((acc, label) => {
  const maxWidth = `${breakpointSizes[label as keyof typeof breakpointSizes] - 1}px`
  acc[label] = (...args: any[]) => css`
    @media (max-width: ${maxWidth}) {
      ${
        // @ts-ignore
        css(...args)
      };
    }
  `

  return acc
}, {})
