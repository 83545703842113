// This file is generated by yarn codegen, PLEASE DO NOT EDIT!
/* eslint-disable */

import * as Types from '../@graph/types/global.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateTimeZoneMutationMutationVariables = Types.Exact<{
  input: Types.UpdateTimeZoneInput;
}>;


export type UpdateTimeZoneMutationMutation = { __typename?: 'Mutation', updateTimeZone: { __typename?: 'UpdateTimeZonePayload', user: { __typename?: 'User', id: string, timeZone: string | null } | null } | null };


export const UpdateTimeZoneMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTimeZoneMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateTimeZoneInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTimeZone"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"timeZone"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateTimeZoneMutationMutation, UpdateTimeZoneMutationMutationVariables>;