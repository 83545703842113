import styled from 'styled-components'
import {
  border,
  BorderProps,
  shadow,
  ShadowProps,
  padding,
  layout,
  LayoutProps,
  PaddingProps,
  color,
  ColorProps,
} from 'styled-system'

export type ContentBlockProps = {
  width?: LayoutProps['width']
  maxWidth?: LayoutProps['maxWidth']
  bg?: ColorProps['bg']
} & PaddingProps &
  BorderProps &
  ShadowProps

/**
 * A full width layout that centers it's children horizontally and limits their width.
 *
 * Inspired by the Braid design system
 *
 * https://seek-oss.github.io/braid-design-system/components/ContentBlock/
 */
export const ContentBlock = styled.div<ContentBlockProps>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  ${padding}
  ${border}
  ${shadow}
  ${({ theme, bg }) => color({ theme, bg })}
  > * {
    ${({ width, maxWidth, theme }) => layout({ theme, width, maxWidth })};
  }
`

ContentBlock.displayName = 'ContentBlock'
