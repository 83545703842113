import { MouseEventHandler, useCallback } from 'react'
import css from '@styled-system/css'
import { layout, MinWidthProps, MaxWidthProps, variant, WidthProps } from 'styled-system'
import styled, { css as styledCss, StyledComponentProps } from 'styled-components'

import { gradient as gradientStyle, gradientForType, GradientType } from '@ui/theme/gradients'
import { Analytics } from 'analytics/Analytics'
import { Theme } from '@ui/theme'

type CommonButtonProps = {
  eventClickTarget?: string
  selected?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLElement>
}

export type ConditionalButtonProps =
  | {
      variant?: 'normal' | 'flat' | 'transparent'
    }
  | {
      variant?: 'gradient'
      gradient?: GradientType
    }

export type CombinedButtonProps = CommonButtonProps &
  ConditionalButtonProps &
  WidthProps &
  MaxWidthProps &
  MinWidthProps

type ButtonProps = StyledComponentProps<'button', Theme, CombinedButtonProps, ''>

export const buttonVariants = variant({
  prop: 'variant',
  variants: {
    normal: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'grey.3',
      boxShadow: 'button',
      willChange: 'box-shadow, transform',
      transition: 'box-shadow 0.1s linear, transform 0.1s linear',
      ':hover': {
        transform: 'translateY(-1px)',
        boxShadow: 'buttonHover',
      },
      ':active': {
        boxShadow: 'button',
        transform: 'translateY(0)',
      },
    },
    flat: {
      backgroundColor: 'transparent',
      willChange: 'background-color',
      transition: 'background-color 0.2s linear',
      padding: 4,
      ':hover': {
        backgroundColor: 'darkGrey35',
      },
      ':active': {
        backgroundColor: '  darkGrey35',
      },
    },
    gradient: {
      borderImage: gradientStyle.neutral,
      backgroundColor: 'white',
      borderWidth: '3px',
      borderStyle: 'solid',
      borderImageSlice: 1,
      boxShadow: 'button',
      willChange: 'box-shadow, transform',
      transition: 'box-shadow 0.1s linear, transform 0.1s linear',
      ':hover': {
        boxShadow: 'buttonHover',
        transform: 'translateY(-1px)',
      },
      ':active': {
        boxShadow: 'button',
        transform: 'translateY(0)',
      },
    },
    transparent: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'grey.3',
      backgroundColor: 'transparent',
      willChange: 'background-color',
      transition: 'background-color 0.2s linear',
      padding: 4,
      ':hover': {
        backgroundColor: 'darkGrey35',
      },
      ':active': {
        backgroundColor: '  darkGrey35',
      },
    },
  },
})

const Button = ({
  onClick,
  eventClickTarget,
  variant = 'normal',
  selected = false,
  disabled = false,
  ...rest
}: ButtonProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (eventClickTarget) Analytics.trackButtonClick(eventClickTarget)
      if (onClick) onClick(e)
    },
    [eventClickTarget, onClick]
  )

  return (
    <ButtonElement
      onClick={(e) => handleClick(e)}
      variant={variant}
      selected={selected}
      disabled={disabled}
      data-event-click-target={eventClickTarget}
      {...rest}
    />
  )
}

const BUTTON_HEIGHT = 52

const getDisabledStyles = ({ disabled, variant }: ButtonProps) => {
  if (!disabled) return ''

  switch (variant) {
    case 'normal':
      return css({
        borderColor: 'grey.2',
        color: 'grey.2',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: 'white',
          boxShadow: 'none',
          transform: 'none',
        },
      })
    case 'flat':
    case 'transparent':
      return css({
        color: 'grey.2',
        ':hover': {
          backgroundColor: 'transparent',
        },
      })
    case 'gradient':
      return css({
        color: 'grey.2',
        opacity: 0.7,
        ':hover': {
          boxShadow: 'button',
          transform: 'none',
        },
      })
    default:
      return ''
  }
}

const getSelectedStyles = ({ disabled, variant, selected }: CombinedButtonProps) => {
  if (!selected) return ''

  switch (variant) {
    case 'normal':
      return css({
        backgroundColor: 'grey.3',
        boxShadow: 'none',
        color: disabled ? 'grey.2' : 'white',
        ':hover': {
          backgroundColor: 'grey.3',
          boxShadow: 'none',
        },
      })
    case 'flat':
    case 'transparent':
      return css({
        backgroundColor: 'mediumGrey25',
        ':hover': {
          backgroundColor: 'mediumGrey25',
        },
      })
    default:
      return ''
  }
}

const getGradientStyles = ({ variant, ...rest }: CombinedButtonProps) => {
  if (variant !== 'gradient') return ''
  const gradient = gradientForType((rest as any).gradient || ('neutral' as GradientType))
  return css({
    borderImage: gradient,
    borderImageSlice: 1,
  })
}

export const commonButtonStyles = styledCss<CombinedButtonProps>`
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  letter-spacing: 2px;
  line-height: 21px;
  text-transform: uppercase;
  min-height: ${BUTTON_HEIGHT}px;
  max-height: ${BUTTON_HEIGHT}px;
  text-align: center;
  outline: 0;
  user-select: none;
  text-decoration: none;
  border-radius: 0;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    color: 'grey.3',
    fontSize: 0,
    fontWeight: 'bold',
    px: 4,
    py: 0,
    minWidth: 'auto',
    ':active': {
      textDecoration: 'none',
    },
  })}

  ${buttonVariants}
  ${(props) => getDisabledStyles(props)}
  ${(props) => getSelectedStyles(props)}
  ${(props) => getGradientStyles(props)}
  ${(layout as any).width}
  ${(layout as any).maxWidth}
  ${(layout as any).minWidth}
`

const ButtonElement = styled.button`
  ${commonButtonStyles}

  /* Safari does not allow buttons to be flex items */
  display: inline-block;
`

export default Button
