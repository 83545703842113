export type Locale = (typeof SUPPORTED_LOCALES)[number]
export type CountryCode = (typeof SUPPORTED_COUNTRIES)[number]
export type CountryISOCode = (typeof SUPPORTED_COUNTRIES_ISO_CODES)[number]

export const VIEWER_LOCALE_COOKIE_NAME = 'viewer_locale'

export const USA_LOCALE = 'en-us'
export const CANADA_LOCALE = 'en-ca'

export const DEFAULT_LOCALE: Locale = USA_LOCALE

export const SUPPORTED_COUNTRIES = <const>['US', 'CANADA']

export const SUPPORTED_LOCALES = <const>[USA_LOCALE, CANADA_LOCALE]

// ISO 3166-1 Alpha-2 code
export const USA_ISO_CODE = 'us'
export const CANADA_ISO_CODE = 'ca'
export const SUPPORTED_COUNTRIES_ISO_CODES = <const>[USA_ISO_CODE, CANADA_ISO_CODE]

export const LOCALE_MAPPING: {
  [key in Locale | 'default']: {
    locale: Locale
    db: CountryCode
    localeCode: string
    isoCode: CountryISOCode
  }
} = {
  default: { locale: USA_LOCALE, db: 'US', localeCode: 'en_US', isoCode: USA_ISO_CODE },
  [USA_LOCALE]: { locale: USA_LOCALE, db: 'US', localeCode: 'en_US', isoCode: USA_ISO_CODE },
  [CANADA_LOCALE]: {
    locale: CANADA_LOCALE,
    db: 'CANADA',
    localeCode: 'en_CA',
    isoCode: CANADA_ISO_CODE,
  },
}
