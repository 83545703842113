import { ReactNode, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

type BaseTransitionProps = {
  children: ReactNode
  className?: string
  speed?: number
}

const BaseTransition = ({ children, className, speed, ...props }: BaseTransitionProps) => {
  const nodeRef = useRef(null)

  return (
    <CSSTransition
      nodeRef={nodeRef}
      timeout={speed}
      classNames='transition'
      addEndListener={() => {}}
      {...props}
    >
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    </CSSTransition>
  )
}

BaseTransition.defaultProps = {
  speed: 200,
}
export default BaseTransition
